import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { FiArrowRightCircle } from 'react-icons/fi';

const Index = ({fullMenu, closeMenu}) => {
    const [data, setData] = useState([])
    function groupBySectionTitleToArrays(items) {
        // Сначала создаем объект для группировки
        const grouped = items.reduce((result, item) => {
            const sectionTitle = item?.section_title;
    
            // Если раздел еще не добавлен, создаем новый массив
            if (!result[sectionTitle]) {
                result[sectionTitle] = [];
            }
    
            // Добавляем текущий элемент в соответствующий раздел
            result[sectionTitle].push(item);
    
            return result;
        }, {});
    
        // Затем превращаем объект в массив массивов
        return Object.values(grouped);
    }

    useEffect(() => {
        if (fullMenu && fullMenu.length > 0) {
            const groupedItems = groupBySectionTitleToArrays(fullMenu);
            const sortGroupedItems = [
                groupedItems.find(item => item[0]?.section_title === 'Супы'),
                groupedItems.find(item => item[0]?.section_title === 'Главные блюда'),
                groupedItems.find(item => item[0]?.section_title === 'Салаты'),
                groupedItems.find(item => item[0]?.section_title === 'Напитки'),
                groupedItems.find(item => item[0]?.section_title === 'Пиво разливное'),
                groupedItems.find(item => item[0]?.section_title === 'Выпечка'),
                groupedItems.find(item => item[0]?.section_title === 'Горячие закуски'),
                groupedItems.find(item => item[0]?.section_title === 'Закуски (снэки)'),
                groupedItems.find(item => item[0]?.section_title === 'Коктейли безалкогольные'),
                groupedItems.find(item => item[0]?.section_title === 'Десерты'),
            ]
            setData(sortGroupedItems);
        }
    }, [fullMenu])

    return (
    <div className={styles.bg}>
            <div className={styles.close} onClick={closeMenu}>В Бизнес-ланч&nbsp;<FiArrowRightCircle/></div>
        <div className={styles.wrapper}>
            <div className={styles.list}>
            {
                data?.map((el, i) => 
                    <div className={styles.container} key={i}>
                    <div className={styles.section_title}>{el?.[0]?.section_title}</div>
                    <div className={styles.itemBlock}>
                        {
                            el?.map(_el =>
                                <div 
                                className={styles.item} 
                                key={_el?.id}
                                style={{
                                    // width: `${_el.length > 4 ? `${((1680 - _el.length*32)/_el.length)}px` : '350px'}`,
                                    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                                    backgroundImage: `url(${_el?.image
                                        ?.[0]
                                            ?.Url})`
                                }}
                                >
                                    <div className={styles.item_inner}>
                                        {/* <img src={_el.image[0].Url} className={styles.image}/> */}
                                        <div className={styles.itemAbout}>
                                            <div className={styles.title}>{_el?.title_ru}</div>
                                            <div className={styles.composition}>{_el?.composition}</div>
                                        </div>
                                    <div className={styles.itemPrice}>{_el?.price.slice(0, -3)} ₽</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    </div>
                )
            }
            </div>
        </div>
    </div>
  )
}

export default Index